import React from 'react';

const defaultValues = {
  tenantContext: {
    tenant: '',
    locale: '',
  },
  isBot: false,
  labsConfig: {
    abSwitches: {},
    featureSwitches: [''],
  },
  xsrfToken: '',
  baseUrl: '',
  links: {},
  isIosUserAgent: false,
  isAndroidUserAgent: false,
  userDetails: {
    isLoggedIn: false,
  },
};

type requiredTypes =
  | 'tenantContext'
  | 'labsConfig'
  | 'xsrfToken'
  | 'baseUrl'
  | 'links'
  | 'isIosUserAgent'
  | 'isAndroidUserAgent'
  | 'userDetails';

const EnvironmentContext = React.createContext<Partial<IEnvironmentContext> & Pick<IEnvironmentContext, requiredTypes>>(
  defaultValues,
);

export const EnvironmentProvider = EnvironmentContext.Provider;
export const EnvironmentConsumer = EnvironmentContext.Consumer;

export default EnvironmentContext;

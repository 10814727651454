import experiments from '@/utils/labs/experiments';
import { useEffect } from 'react';
type TGptScriptProps = {
  gptUrl: string;
  labsConfig: TLabsConfig;
  pwtPubId?: string;
  pwtProfId?: string;
};

const GptScript = ({ gptUrl, labsConfig, pwtPubId, pwtProfId }: TGptScriptProps) => {
  const { isPubmaticWrapperEnabled, isPubmaticJsEnabled } = experiments({
    labsConfig,
  });

  const twoSeconds = 2000;

  useEffect(() => {
    if (typeof window === 'undefined' || typeof document === 'undefined') {
      return;
    }

    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];

    let gptLoaded = false;

    const loadGPT = () => {
      if (!gptLoaded) {
        gptLoaded = true;
        const script = document.createElement('script');
        script.src = gptUrl;
        script.async = true;
        document.head.appendChild(script);
      }
    };

    const loadPubmatic = () => {
      let pubmaticUrl;
      if (isPubmaticWrapperEnabled) {
        pubmaticUrl = `//ads.pubmatic.com/AdServer/js/pwt/${pwtPubId}/${pwtProfId}/pwt.js`;
      } else if (isPubmaticJsEnabled) {
        pubmaticUrl = `//ads.pubmatic.com/AdServer/js/pwt/${pwtPubId}/14621/pwt.js`;
      }

      if (pubmaticUrl) {
        const script = document.createElement('script');
        script.src = pubmaticUrl;
        script.async = true;

        script.onload = () => {
          loadGPT();
        };

        script.onerror = () => {
          loadGPT();
        };

        document.head.appendChild(script);
      } else {
        loadGPT();
      }
    };

    loadPubmatic();

    const timeout = setTimeout(() => {
      loadGPT();
    }, twoSeconds);

    return () => {
      clearTimeout(timeout);
    };
  }, [gptUrl, isPubmaticWrapperEnabled, isPubmaticJsEnabled, pwtPubId, pwtProfId]);

  return null;
};

export default GptScript;

import Script from 'next/script';
import React from 'react';

const createAdsenseScript = () => ({
  __html: `(function (G, o, O, g, L, e) { G[g] = G[g] || function () { (G[g]['q'] = G[g]['q'] || []).push(arguments) }, G[g]['t'] = 1 * new Date; })(window, document, 'script', '_googCsa');`,
});

const AdsenseScript = () => (
  <Script
    type="text/javascript"
    charSet="utf-8"
    strategy="lazyOnload"
    /* eslint-disable-next-line react/no-danger */
    dangerouslySetInnerHTML={createAdsenseScript()}
  />
);

export default AdsenseScript;

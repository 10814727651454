import React, { FC } from 'react';

interface ScriptComponentProps extends React.ScriptHTMLAttributes<HTMLScriptElement> {
  children?: string;
}

const ScriptComponent: FC<ScriptComponentProps> = ({ children, ...props }) => {
  if (children) {
    // eslint-disable-next-line react/no-danger
    return <script dangerouslySetInnerHTML={{ __html: children }} {...props} />;
  }

  return <script {...props} />;
};

export default ScriptComponent;

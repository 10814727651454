export const nextPageProps = () => {
  const { props: { pageProps = {} } = {} } = window.__NEXT_DATA__;

  return pageProps;
};

export const nextEnvironment = () => {
  const { props: { environment = {} } = {} } = window.__NEXT_DATA__;

  return environment;
};

export const nextSeller = () => {
  const { props: { seller } = {} } = window.__NEXT_DATA__;

  return seller;
};

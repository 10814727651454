import React from 'react';
import Script from 'next/script';
/**
 * Possible replacement for Reblaze
 * https://jira.ets.mpi-internal.com/browse/HZ-7250
 */
interface AwsWAFScriptProps {
  srcUrl?: string;
}

const AwsWAFScript = ({ srcUrl }: AwsWAFScriptProps) => {
  if (!srcUrl) {
    return null;
  }
  return <Script src={srcUrl} />;
};
export default AwsWAFScript;
